<template>
   <component :is="currentComponent" />
</template>

<script>
   import { defineAsyncComponent } from 'vue';

   export default {
      name: 'AppNotifications',

      props: {
         notificationType: { type: String, required: true }
      },

      computed: {
         currentComponent() {
            const name = this.notificationType;

            return defineAsyncComponent(() => import(`./modules/App${name}`));
         }
      }
   };
</script>
